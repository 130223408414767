<template>
    <div id="page-fibre-service-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading.service" fixed-on="desktop"></app-loader>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <router-link class="btn btn-back-to" :to="{ name: 'fibre-services' }" v-if="!on_sidebar">Back to Fibre Services</router-link>

                    <div class="header-info">
                        <div class="status">Provisioning status: <span class="tag" :class="[ status.value ]">{{ status.text }}</span></div>
                    </div>
                </div>
            </div>

            <div class="row details">
                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <app-table-details
                        :rows="rows.address"
                        :data="address"

                        title="Address information"
                    />
                </div>

                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <app-table-details
                        :rows="rows.plan"
                        :data="plan"

                        title="Plan"
                    />
                </div>

                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <app-table-details
                        :rows="rows.contacts"
                        :data="contacts"

                        title="Contacts"
                    />
                </div>

                <div class="col-mob-12" :class="on_sidebar ? 'col-12' : 'col-6'">
                    <app-table-details
                        :rows="rows.config"
                        :data="serviceconfig"

                        title="Service Config"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
    },

    data() {
        return {
            service: null,
            customer: null,
            plans: {},

            rows: {
                address: [
                    { key: 'Address',   title: 'Address',   editable: false },
                    { key: 'AddressID', title: 'Address ID', editable: false },
                    { key: 'ActivePlaceID', title: 'Place ID', editable: false },
                    { key: 'FeasabilityDEMANDPOINTTYPE',   title: 'Site Type',  editable: false },
                    { key: 'FeasabilityINSTALLTYPE',   title: 'Install Type',  editable: false },
                    { key: 'LFC',       title: 'LFC',       editable: false },
                    { key: 'LFCOrderID',       title: 'LFC Order ID',       editable: false },
                    { key: 'SiteVisitScopeStartDateTime',   title: 'Site Visit Scoping Date',  editable: false },
                    { key: 'SiteVisitInstallStartDateTime',   title: 'Site Visit Install Date',  editable: false },
                    { key: 'RFSDate',   title: 'RFS Date',  editable: false },
                    { key: 'ActiveSERVICEGIVENDATE',   title: 'Service Given Date',  editable: false },
                    
                    // { key: 'SiteVisitInstalCSEStartDateTime',   title: 'Site Visit for InstalCSE',  editable: false },
                    // { key: 'SiteVisitCSEStartDateTime',   title: 'Site Visit for CSE',  editable: false },
                    // { key: 'SiteVisitChangeONTStartDateTime',   title: 'Site Visit for Change ONT',  editable: false },
                    // { key: 'SiteVisitReplaceONTStartDateTime',   title: 'Site Visit for Replace ONT',  editable: false },
                    // { key: 'SiteVisitScopeEndDateTime',   title: 'Site Visit for Scope End',  editable: false },
                    // { key: 'SiteVisitInstallEndDateTime',   title: 'Site Visit for Install End',  editable: false },
                    // { key: 'SiteVisitInstalCSEEndDateTime',   title: 'Site Visit for InstalCSE End',  editable: false },
                    // { key: 'SiteVisitCSEEndDateTime',   title: 'Site Visit for CSE End',  editable: false },
                    // { key: 'SiteVisitChangeONTEndDateTime',   title: 'Site Visit for ChangeONT End',  editable: false },
                    // { key: 'SiteVisitReplaceONTEndDateTime',   title: 'Site Visit for ReplaceONT End',  editable: false },
                ],

                plan: [
                    { key: 'Name',        title: 'Name'        , editable: false},
                    { key: 'Description', title: 'Description' , editable: false},
                    { key: 'Price',       title: 'Price'       , editable: false},
                    { key: 'OrderType',   title: 'Order Type'  , editable: false},
                    { key: 'State',       title: 'State'       , editable: false},
                    { key: 'Substate',       title: 'Sub State'       , editable: false},
                    { key: 'ActiveProductID',    title: 'Active Product ID'   , editable: false},
                    // { key: 'ActiveProductProductOffer',    title: 'Active Product ID 2'   , editable: false},
                    // { key: 'ActiveIntactProductOffer',    title: 'In Tact Product ID'   , editable: false},
                    { key: 'broadbandProfile',    title: 'Bandwidth Profile'   , editable: false},
                ],

                contacts: [
                    { key: 'SiteContactName',         title: 'Site Contact Name',         editable: false },
                    { key: 'SiteContactPhone',        title: 'Site Contact Phone',        editable: false },
                    { key: 'NotificationName',        title: 'Notification Name',         editable: false },
                    { key: 'NotificationPhoneNumber', title: 'Notification Phone Number', editable: false },
                    { key: 'NotificationEmail',       title: 'Notification Email',        editable: false },
                ],

                personal: [
                    { key: 'firstname', title: 'First name'    },
                    { key: 'lastname',  title: 'Last name'     },
                    { key: 'mobile',    title: 'Mobile number' },
                ],

                config: [
                    { key: 'ServiceType',       title: 'Service Type',       editable: false },
                    { key: 'LANIPRange',        title: 'LAN IP Range',       editable: false },
                    { key: 'VRFDetails',        title: 'VRF Details',        editable: false },
                    { key: 'AdditionalDetails', title: 'Additional Details', editable: false },
                    { key: 'DHCPScope',         title: 'DHCP Scope',         editable: false },
                    { key: 'IPHelperDetails',   title: 'IP Helper Details',  editable: false },                    
                    { key: 'WANIP',             title: 'WAN IP',             editable: false },
                    { key: 'ActiveONTID',    title: 'Active ONT ID'   , editable: false},
                    { key: 'handoverID',    title: 'Handover ID'   , editable: false},
                    { key: 'ActiveSVID',    title: 'Active SVID'   , editable: false},
                    { key: 'ActiveCVID',    title: 'Active CVID'   , editable: false},
                    { key: 'ActiveUNIPORTNUMBER',    title: 'Active LAN Port'   , editable: false},
                    { key: 'ActiveUNIPORTTYPE',    title: 'LAN Port Speed'   , editable: false},
                    // { key: 'ActiveUNITAGGING',    title: 'Active UNI Tagging'   , editable: false},
                    // { key: 'ActiveUNIVLAN',    title: 'Active UNI VLAN'   , editable: false},
                ],
            },


            loading: {
                service:  false,
                customer: false,
                plans:    false,
            },

            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.loadServiceDetails()
        },

        loadServiceDetails() {
            this.loading.service = true

            this.$store.dispatch('api_ufbprod/GetUFBProductByUUID', { SPID: this.current_spid, UUID: this.service_uuid }).then(service => {
                this.service = service

                // this.loadCustomerInfo()

                if (this.service.ProductAvailabilityUUID) {
                    this.getPlans()
                }

                this.loading.service = false
            }).catch(error => {
                console.log('Error getFibreServiceByUUID', this.service_uuid)
                console.dir(error)

                if (this.on_sidebar) {
                    this.$emit('close-sidebar')
                } else {
                    this.$router.push({ name: 'fibre-services', replace: true })
                }
            })
        },

        loadCustomerInfo() {
            // this.loading.customer = true

            // this.$store.dispatch('getCustomerByUUID', { uuid: this.service.CustomerUUID }).then(customer => {
            //     this.customer = customer

            //     this.loading.customer = false
            // }).catch(error => {
            //     console.log('Error getCustomerByUUID', this.service.CustomerUUID)
            //     console.error(error)

            //     this.loading.customer = false
            // })
        },

        getPlans() {
            if (!Object.keys(this.plans).length) {
                this.loading.plans = true

                const params = {
                    SPID:        this.current_spid,
                    GetAllPlans: true,
                }

                this.$store.dispatch('api_ufbprod/GetUFBPlans', params)
                    .then(({ UFBPlans }) => {
                        const plans = {}

                        for (let i = 0, len = UFBPlans.length; i < len; i++) {
                            plans[UFBPlans[i].UUID] = {
                                Name: UFBPlans[i].Name,
                                Description: UFBPlans[i].Description,
                                Price: `$${ UFBPlans[i].Cents % 100 == 0 ? UFBPlans[i].Cents / 100 : (UFBPlans[i].Cents / 100).toFixed(2) }`,
                            }
                        }

                        this.plans = plans

                        this.loading.plans = false
                    })
                    .catch(error => {
                        this.$store.dispatch('addToast', {
                            message: errMessage(error),
                            type: 'error',
                            delay: 5000,
                        })

                        this.loading.plans = false
                    })
            }
        },
    },

    watch: {
        service_uuid() {
            this.loadServiceDetails()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        service_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        status() {
            return this.service ? {
                text: this.service.State,
                value: this.service.State.toLowerCase().replace(/[^\w\d]/g, ''),
            } : {
                text: 'Loading',
                value: 'info',
            }
        },

        address() {
            return this.service && this.service.Address ? {
                Address: this.service.Address || '?',
                AddressID: this.service.AddressID || '?',
                ActivePlaceID: this.service.ActivePlaceID || '?',
                LFC: this.service.LFC || '?',
                LFCOrderID: this.service.LFCOrderID || '',
                RFSDate: formatDateNano(this.service.ActiveCONNECTRFSDATETIME) || formatDateNano(this.service.RFSDate) || 'TBC',
                ActiveSERVICEGIVENDATE: formatDateNano(this.service.ActiveSERVICEGIVENDATE) || '-',
                FeasabilityDEMANDPOINTTYPE: this.service.FeasabilityDEMANDPOINTTYPE || '?',
                FeasabilityINSTALLTYPE: this.service.FeasabilityINSTALLTYPE || '?',
                FeasabilityBUILDREQUIRED: this.service.FeasabilityBUILDREQUIRED || '?',
                FeasabilityCONSENTREQUIRED: this.service.FeasabilityCONSENTREQUIRED || '?',
                FeasabilityDESIGNREQUIRED: this.service.FeasabilityDESIGNREQUIRED || '?',

                SiteVisitScopeStartDateTime: this.service.SiteVisitScopeStartDateTime ? formatDateNano(this.service.SiteVisitScopeStartDateTime) : '',
                SiteVisitInstallStartDateTime: this.service.SiteVisitInstallStartDateTime ? formatDateNano(this.service.SiteVisitInstallStartDateTime) : '',
                SiteVisitInstalCSEStartDateTime: this.service.SiteVisitInstalCSEStartDateTime ? formatDateNano(this.service.SiteVisitInstalCSEStartDateTime) : '',
                SiteVisitCSEStartDateTime: this.service.SiteVisitCSEStartDateTime ? formatDateNano(this.service.SiteVisitCSEStartDateTime) : '',
                SiteVisitChangeONTStartDateTime: this.service.SiteVisitChangeONTStartDateTime ? formatDateNano(this.service.SiteVisitChangeONTStartDateTime) : '',
                SiteVisitReplaceONTStartDateTime: this.service.SiteVisitReplaceONTStartDateTime ? formatDateNano(this.service.SiteVisitReplaceONTStartDateTime) : '',
                SiteVisitScopeEndDateTime: this.service.SiteVisitScopeEndDateTime ? formatDateNano(this.service.SiteVisitScopeEndDateTime) : '',
                SiteVisitInstallEndDateTime: this.service.SiteVisitInstallEndDateTime ? formatDateNano(this.service.SiteVisitInstallEndDateTime) : '',
                SiteVisitInstalCSEEndDateTime: this.service.SiteVisitInstalCSEEndDateTime ? formatDateNano(this.service.SiteVisitInstalCSEEndDateTime) : '',
                SiteVisitCSEEndDateTime: this.service.SiteVisitCSEEndDateTime ? formatDateNano(this.service.SiteVisitCSEEndDateTime) : '',
                SiteVisitChangeONTEndDateTime: this.service.SiteVisitChangeONTEndDateTime ? formatDateNano(this.service.SiteVisitChangeONTEndDateTime) : '',
                SiteVisitReplaceONTEndDateTime: this.service.SiteVisitReplaceONTEndDateTime ? formatDateNano(this.service.SiteVisitReplaceONTEndDateTime) : '',
            } : {
                Address: '?',
                AddressID: '?',
                LFC: '?',
                RFSDate: '?',
                ServiceType: '?',
            }
        },

        plan() {
            return this.service
                && this.service.ProductAvailabilityUUID
                && this.service.ProductAvailabilityUUID in this.plans
                    ? {
                        Name: this.plans[this.service.ProductAvailabilityUUID].Name,
                        Description: this.plans[this.service.ProductAvailabilityUUID].Description,
                        Price: this.plans[this.service.ProductAvailabilityUUID].Price,
                        OrderType: this.service.OrderType,
                        State: this.service.State,
                        Substate: this.service.Substate,
                        ActiveProductID: this.service.ActiveProductID,
                        ActiveProductProductOffer: this.service.ActiveProductProductOffer,
                        ActiveIntactProductOffer: this.service.ActiveIntactProductOffer,
                        broadbandProfile: this.service.ActiveDOWNSTREAM_LP_EIR ? `${this.service.ActiveDOWNSTREAM_LP_EIR}/${this.service.ActiveUPSTREAM_LP_EIR} CIR: ${this.service.ActiveDOWNSTREAM_HP}/${this.service.ActiveUPSTREAM_HP}` : this.service.ActiveBANDWIDTHPROFILE,
                    }
                    : {
                        Name: '?',
                        Description: '?',
                        Price: '?',
                        OrderType: '?',
                        State: '?',
                        Substate: '?',
                    }
        },

        contacts() {
            return this.service ? {
                SiteContactName:         this.service.SiteContactName         || '?',
                SiteContactPhone:        this.service.SiteContactPhone        || '?',
                NotificationName:        this.service.NotificationName        || '?',
                NotificationPhoneNumber: this.service.NotificationPhoneNumber || '?',
                NotificationEmail:       this.service.NotificationEmail       || '?',
            } : {}
        },

        personal() {
            return this.customer ? {
                firstname: this.customer.FirstName    || '?',
                lastname:  this.customer.LastName     || '?',
                mobile:    this.customer.MobileNumber || '?',
            } : {
                firstname: '-',
                lastname: '-',
                mobile: '-',
            }
        },

        serviceconfig() {
            return this.service ? {
                ServiceType:       this.service.ServiceType       || 'n/a',
                LANIPRange:        this.service.LANIPRange        || 'n/a',
                VRFDetails:        this.service.VRFDetails        || 'n/a',
                AdditionalDetails: this.service.AdditionalDetails || 'n/a',

                DHCPScope: typeof this.service.DHCPScope == 'boolean'
                    ? this.service.DHCPScope ? 'Yes' : 'No'
                    : 'n/a',

                IPHelperDetails:   this.service.IPHelperDetails   || 'n/a',
                WANIP:             this.service.WANIP             || 'n/a',
                ActiveDATAHANDOVERCONNECTIONID: this.service.ActiveDATAHANDOVERCONNECTIONID,
                ActiveVOICEHANDOVERCONNECTIONID: this.service.ActiveVOICEHANDOVERCONNECTIONID,
                handoverID: this.service.ActiveDATAHANDOVERCONNECTIONID || this.service.HandoverID,
                
                ActiveONTID: this.service.ActiveONTID,
                ActiveSVID: this.service.ActiveSVID ? this.service.ActiveSVID : this.service.ActiveDATAVLANSVID,
                ActiveCVID: this.service.ActiveCVID ? this.service.ActiveCVID : this.service.ActiveDATAVLANCVID,
                ActiveUNIPORTNUMBER: this.service.ActiveUNIPORTNUMBER ? this.service.ActiveUNIPORTNUMBER : '',
                ActiveUNIPORTTYPE: this.service.ActiveUNIPORTTYPE ? this.service.ActiveUNIPORTTYPE : '',
                ActiveUNITAGGING: this.service.ActiveUNITAGGING || this.service.ActiveUNITaggin || '',
                ActiveUNIVLAN: this.service.ActiveUNIVLAN,
            } : {}
        },
    },
}
</script>

<style lang="scss">
#page-fibre-service-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;

        .status {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-default);

            .tag {
                margin-left: 16px;
                padding: 4px 12px;
                font-weight: bold;
                text-align: center;
                border-radius: $border-radius-primary;
                
                @each $type in $table-tag-types {
                    &.#{ $type } {
                        color: map-get($table-tag-colors, $type );
                        background-color: map-get($table-tag-backgrounds, $type );
                    }
                }
            }
        }
    }

    .col-12 {
        margin-top: 30px;

        &:first-child { margin: 0 };
    }
}

@media (max-width: $tablet-size) {
    #page-fibre-service-details {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;

            .status {
                font-size: 14px;
                line-height: 24px;
                margin-left: 16px;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-fibre-service-details {
        margin: 24px 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;

            .status {
                margin: 8px 0 0;
                justify-content: flex-start;

                .tag {
                    margin-left: 12px;
                    line-height: 20px;
                }
            }
        }

        .details {
            .col-mob-12 {
                margin-top: 15px;

                &:first-child { margin: 0; }
            }
        }
    }
}
</style>